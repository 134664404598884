import React, { useContext, useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  getFieldValue,
  Text,
  Link,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";
import { MultiStepContext } from "../../contexts/Steps.context";
import { InlineFormError } from "../../common/InlineFormError";

import {
  ICON_PATH,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  VALIDATE_MEMBERSHIP_ATTEMPT_FIRST,
  SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT,
  VALIDATE_MEMBERSHIP_ATTEMPT_SECOND,
  VALIDATE_MEMBERSHIP_ATTEMPT_THIRD,
  VALIDATE_MEMBERSHIP_ATTEMPT_NEW,
  VALIDATION_MEMBERSHIP_ATTEMPT,
  VALIDATE_MEMBERSHIP_ATTEMPT_ONE,
  REGISTRATION_LOGIN_RESTRICTION,
  MEMBER_ID_NO,
  MEDICARE_CARD_NO,
  SSN_NO,
  HIDE_EMAIL_CHARACTERS,
  SSN_MULTIPLE_MEMBER_FOUND,
  SSN_MEMBER_ALREADY_REGISTERED,
  SSN_MEMBER_NOT_FOUND,
  SSN_MEMMASTER_ALREADY_HASRECORD,
  NO_MEMBER_FOUND_MEDICARE_ATTEMPTFIRST,
  MEDICARE_MEMBER_ALREADY_REGISTERED,
  MULTIPLE_MEMBERS_FOUND_MEDICARE,
} from "../../define.constants";
import {
  getCaptcha,
  highlightBorderOnError,
  handleLogin,
} from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { Calendar } from "../../common/Calendar";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "./../../contexts/modal.context";

const MemberInformation = (props) => {
  const { fields } = props;
  const { selectedDate } = props;
  const { setSelectedDate } = props;
  const [statusCode, setStatusCode] = useState("");
  const [enrollStateOptions, setEnrollStateOptions] = useState([]);
  const [enrollStateList, setEnrollStateList] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [hover, setHover] = useState(false);
  const [memberIdError, setMemberIdError] = useState(false);
  const [medicareIdError, setMedicareIdError] = useState(false);
  const [ssnError, setSsnError] = useState(false);
  const [recordRetrievalFail, setRecordRetrievalFail] = useState(0);
  const [recordRetrievalFailMedicare, setRecordRetrievalFailMedicare] =
    useState(0);
  const [recordRetrievalSSN, setRecordRetrievalFailSSN] = useState(0);
  const [visible, setVisible] = useState(false); //for Password Field
  const [confirmVisible, setConfirmVisible] = useState(false); //for Password Field
  const refMsgDetails = useRef(null);
  const eye = `${ICON_PATH}/visibility-24px.svg`;
  const eyeSlash = `${ICON_PATH}/visibility_off_black_24dp.svg`;
  let str = HIDE_EMAIL_CHARACTERS.toLowerCase();
  const pwdVisibilityIconConfirm = (
    <img
      src={confirmVisible ? eye : eyeSlash}
      alt={getFieldValue(fields, "pwd")}
      onClick={() => setConfirmVisible(!confirmVisible)}
      className="pwd-icon"
    />
  );
  const pwdInputTypeConfirm = confirmVisible ? "text" : "password";

  const pwdVisibilityIcon = (
    <img
      src={visible ? eye : eyeSlash}
      alt={getFieldValue(fields, "pwd")}
      onClick={() => setVisible(!visible)}
      className="pwd-icon"
    />
  );
  const pwdInputType = visible ? "text" : "password";
  const onHover = () => {
    setHover(true);
  };
  const onLeave = () => {
    setHover(false);
  };
  const stateOptionsArray = [];
  const required = <span style={{ color: "red" }}>*</span>;

  const {
    setStep,
    memberId,
    selectedPlan,
    setSelectedPlan,
    setMemberId,
    setFullMedicareNo,
    medicareno,
    setMedicareno,
    medicareNoSecond,
    setMedicareNoSecond,
    medicareNoThird,
    setMedicareNoThird,
    ssnFeild3,
    setSsnFeild3,
    confirmSsnField3,
    enrollState,
    setEnrollState,
    dob,
    setDob,
    setBrowserSessionId,
  } = useContext(MultiStepContext);
  const refMsgDetailsOutside = useRef(null);
  const refConfirmSSNOutside = useRef(null);
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);

  useEffect(() => {
    getCaptcha();
    getEnrollStateList();
  }, []);
  const handleClickOutside = (ref, setState) => (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setState(false);
    }
  };
  // Usage for setVisible
  const handleClickOutsideVisible = handleClickOutside(
    refMsgDetailsOutside,
    setVisible
  );

  // Usage for setConfirmVisible
  const handleClickOutsideConfirmVisible = handleClickOutside(
    refConfirmSSNOutside,
    setConfirmVisible
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideVisible);
    document.addEventListener("mousedown", handleClickOutsideConfirmVisible);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener(
        "mousedown",
        handleClickOutsideConfirmVisible
      );
    };
  }, []);
  const [validateMembership, setValidationMsg] = useState("");
  const memberIdCardText = () => {
    return (
      <>
        {" "}
        <div>
          <Text field={fields.memberIdText} />
        </div>
      </>
    );
  };
  const { openModal, closeModal } = useContext(ModalContext);
  const handleChangeSSNorMemberID = (e) => {
    if (e?.target?.value == MEMBER_ID_NO) {
      setValidationMsg("");
      setSelectedPlan(MEMBER_ID_NO);
    }

    if (e?.target?.value == SSN_NO) {
      setValidationMsg("");
      setSelectedPlan(SSN_NO);
    }
    if (e?.target?.value == MEDICARE_CARD_NO) {
      setValidationMsg("");
      setSelectedPlan(MEDICARE_CARD_NO);
    }
  };
  const [inputValueSecond, setInputValueSecond] = useState("");

  const getEnrollStateList = () => {
    setSpinner(true);
    RegistrationAPI.getStateListForReg({
      StateListFor: "Member",
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE
          ) {
            setEnrollStateList([]);
            setEnrollStateList(data.ResultData);
            data.ResultData?.map((item) => {
              if (item.StateName !== "Select") {
                stateOptionsArray.push({
                  value: item.StateName,
                  label: item.StateName,
                  id: item.StateCode,
                });
              }
            });
          }
          setEnrollStateOptions([...stateOptionsArray]);
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const memberIdValidation = (e, values, setFieldValue) => {
    setTimeout(() => {
      const memberId = e.target.value;
      const SSN = e.target.value;
      setMemberIdError(false);
      let nonNum, Num, zeroNum;

      let regExp_alphanum = new RegExp("^[a-zA-Z0-9]*$"); //RegEx for AlphaNumeric

      if (
        memberId != null &&
        memberId != "" &&
        regExp_alphanum.test(memberId)
      ) {
        // Checking For AlphaNumeric Or Numeric(No special characters are allowed)
        nonNum = memberId.replace(/\d+/g, "").length; // Number of nonNumeric characters
        Num = memberId.replace(/\D+/g, "").length; // Number of digits
        zeroNum = memberId.replace(/\D+/g, "").replace(/[1-9]+/g, "").length; // Number of zeroes
        if (
          memberId.length < 4 ||
          (nonNum > 4 && isNaN(memberId)) ||
          Num < 3 ||
          Num == zeroNum
        ) {
          setMemberIdError(true);
          setValidationMsg(getFieldValue(fields, "invalidMemberId"));
          return true;
        } else {
          setValidationMsg("");
          setMemberIdError(false);
        }
      } else {
        if (e.type === "paste") {
          setFieldValue("memberId", "");
        }
        setMemberIdError(false);
        setValidationMsg("");
        return false;
      }
    }, 10);
  };

  const hasErrors = (errors) => {
    console.log("errors", errors);
    if (selectedPlan === SSN_NO) {
      return (
        errors.dob ||
        errors.ssnFeild3 ||
        errors.confirmSsnField3 ||
        errors.enrollState
      );
    } else if (selectedPlan === MEDICARE_CARD_NO) {
      return (
        errors.dob ||
        errors.medicareno ||
        errors.medicareNoSecond ||
        errors.medicareNoThird ||
        errors.enrollState
      );
    } else {
      return errors.dob || errors.memberId || errors.enrollState;
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          enrollState,
          memberId,
          dob,
          medicareno,
          medicareNoThird,
          medicareNoSecond,
          selectedOption: selectedPlan,
          ssnFeild3,
          confirmSsnField3,
        }}
        validationSchema={Yup.object({
          selectedOption: Yup.string().required(""),
          enrollState: Yup.string().required(" "),
          medicareno: Yup.string().when("selectedOption", {
            is: MEDICARE_CARD_NO,
            then: Yup.string()
              .required(" ")
              .test(
                "len",
                getFieldValue(fields, "invalidFormat"),
                (val) => val && val.length === 4
              ),
          }),
          medicareNoSecond: Yup.string().when("selectedOption", {
            is: MEDICARE_CARD_NO,
            then: Yup.string()
              .required(" ")
              .test(
                "len",
                getFieldValue(fields, "invalidFormat"),
                (val) => val && val.length === 3
              ),
          }),
          medicareNoThird: Yup.string().when("selectedOption", {
            is: MEDICARE_CARD_NO,
            then: Yup.string()
              .required(" ")
              .test(
                "len",
                getFieldValue(fields, "invalidFormat"),
                (val) => val && val.length === 4
              ),
          }),
          memberId: Yup.string().when("selectedOption", {
            is: MEMBER_ID_NO,
            then: Yup.string()
              .required(" ")
              .min(3, getFieldValue(fields, "invalidMemberId")),
          }),
          ssnFeild3: Yup.string().when("selectedOption", {
            is: SSN_NO,
            then: Yup.string()
              .required(" ")
              .test(
                "len",
                getFieldValue(fields, "invalidFormat"),
                (val) => val && val.length === 4
              ),
          }),
          confirmSsnField3: Yup.string().when("selectedOption", {
            is: SSN_NO,
            then: Yup.string()
              .required(" ")
              .oneOf(
                [Yup.ref("ssnFeild3"), null],
                getFieldValue(fields, "doesNotMatchSsn")
              ),
          }),
          dob: Yup.date().required(" "),
        })}
        onSubmit={(values) => {
          let selectedState = enrollStateList.find(
            (item) => item.StateCode === values.enrollState
          );
          if (!sessionStorage.getItem("sessionId")) {
            setBrowserSessionId();
          }

          setIsSubmited(true);
          setMemberId(values.memberId);
          setMedicareno(values.medicareno);
          setSsnFeild3(values.ssnFeild3);
          setMedicareNoSecond(values.medicareNoSecond);
          setMedicareNoThird(values.medicareNoThird);
          setEnrollState(selectedState?.StateCode);
          setDob(values.dob);
          setSpinner(true);

          const medicareIdNumber =
            `${values.medicareno}${values.medicareNoSecond}${values.medicareNoThird}`.toUpperCase();
          if (selectedPlan === MEDICARE_CARD_NO) {
            RegistrationAPI.getValidateMedicareRegistration({
              MedicarememNo: medicareIdNumber,
              Dob: values.dob,
              StateCode: values.enrollState,
              SupportUserID: "N",
              IsMedicare: "Y",
              SessionId: sessionStorage.getItem("sessionId"),
              isMedicareSelected: true,
              AttempCountValue: recordRetrievalFailMedicare,
            })
              .then((data) => {
                setStatusCode(data?.ResultCode);
                if (data) {
                  if (
                    data.Message === SUCCESS_RESPONSE &&
                    data.ResultCode === SUCCESS_RESULT_CODE
                  ) {
                    setMemberId(data.ResultData.MemberNumber);
                    setFullMedicareNo(medicareIdNumber);
                    setStep(2);
                  } else if (
                    data.ResultCode === NO_MEMBER_FOUND_MEDICARE_ATTEMPTFIRST
                  ) {
                    setRecordRetrievalFailMedicare((count) => count + 1);
                    if (data.ResultData.AttempCountValue === 2) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidateSecondAttemptErrorMsg")
                      );
                    } else {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError1")
                      );
                    }
                  } else if (
                    data.ResultCode === MEDICARE_MEMBER_ALREADY_REGISTERED ||
                    data.ResultCode === VALIDATION_MEMBERSHIP_ATTEMPT
                  ) {
                    setValidationMsg(getFieldValue(fields, "ValidationError2"));
                  } else if (
                    data.ResultCode === SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT
                  ) {
                    setValidationMsg(getFieldValue(fields, "ValidationError3"));
                  } else if (
                    data.ResultCode === MULTIPLE_MEMBERS_FOUND_MEDICARE ||
                    data.ResultCode === SSN_MULTIPLE_MEMBER_FOUND
                  ) {
                    setRecordRetrievalFailMedicare((count) => count + 1);
                    setValidationMsg(getFieldValue(fields, "ValidationError1"));
                  }
                } else {
                  setValidationMsg(getFieldValue(fields, "invalidMedicareId"));
                }
              })
              .catch(function (err) {
                console.log(err);
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          }
          if (selectedPlan === SSN_NO) {
            RegistrationAPI.getValidateSSNRegistration({
              SSN: values.ssnFeild3,
              DOB: values.dob,
              StateCode: values.enrollState,
              SupportUserID: "N",
              SessionID: sessionStorage.getItem("sessionId"),
              isSSNSelected: true,
              AttempCountValue: recordRetrievalSSN,
            })
              .then((data) => {
                setStatusCode(data?.ResultCode);
                if (data) {
                  if (
                    data.Message === SUCCESS_RESPONSE &&
                    data.ResultCode === SUCCESS_RESULT_CODE
                  ) {
                    setMemberId(data.ResultData.MemberNumber);
                    setStep(2);
                  } else if (
                    data.ResultCode === SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT
                  ) {
                    setRecordRetrievalFailSSN((count) => count + 1);

                    setValidationMsg(getFieldValue(fields, "ValidationError3"));
                  } else if (
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST
                  ) {
                    setRecordRetrievalFailSSN((count) => count + 1);
                    setValidationMsg(getFieldValue(fields, "ValidationError1"));
                  } else if (
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST ||
                    data.ResultCode === SSN_MEMBER_NOT_FOUND ||
                    data.ResultCode === SSN_MULTIPLE_MEMBER_FOUND
                  ) {
                    setRecordRetrievalFailSSN((count) => count + 1);
                    if (data?.ResultData?.AttempCountValue === 1) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidateSSNErrorMessageSecond")
                      );
                    } else if (data?.ResultData?.AttempCountValue === 2) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidateSecondAttemptErrorMsg")
                      );
                    } else if (data?.ResultData?.AttempCountValue >= 3) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError3")
                      );
                    } else {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError3")
                      );
                    }
                  } else if (
                    data.ResultCode === SSN_MEMBER_ALREADY_REGISTERED ||
                    data.ResultCode === SSN_MEMMASTER_ALREADY_HASRECORD
                  ) {
                    setRecordRetrievalFailSSN((count) => count + 1);
                    if (data?.ResultData?.AttempCountValue >= 3) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError3")
                      );
                    } else {
                      setValidationMsg(
                        getFieldValue(fields, "ValidateSSNErrorMessageFirst")
                      );
                    }
                  }
                } else {
                  setValidationMsg(
                    getFieldValue(fields, "FormLevelErrorMessage")
                  );
                  console.log("error", data);
                }
              })
              .catch(function (err) {
                console.log(err);
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          }
          if (selectedPlan === MEMBER_ID_NO) {
            RegistrationAPI.getValidateMembership({
              MemberNumber: values.memberId,
              Dob: values.dob,
              StateCode: values.enrollState,
              SessionId: sessionStorage.getItem("sessionId"),
              AttemptCountVal: recordRetrievalFail,
            })
              .then((data) => {
                setStatusCode(data?.ResultCode);
                if (data) {
                  if (
                    data.Message === SUCCESS_RESPONSE &&
                    data.ResultCode === SUCCESS_RESULT_CODE
                  ) {
                    setStep(2);
                  } else if (
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST ||
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_SECOND ||
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_ONE
                  ) {
                    setRecordRetrievalFail((count) => count + 1);
                    if (data.ResultData.AttempCountValue === 2) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidatesecAttempt")
                      );
                    } else if (data.ResultData.AttempCountValue >= 3) {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError3")
                      );
                    } else {
                      setValidationMsg(
                        getFieldValue(fields, "ValidationError1")
                      );
                    }
                  } else if (
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_NEW ||
                    data.ResultCode === VALIDATION_MEMBERSHIP_ATTEMPT
                  ) {
                    setValidationMsg(
                      <RichText field={fields.ValidationError2} />
                    );
                  } else if (
                    data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_THIRD ||
                    data.ResultCode === SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT
                  ) {
                    setRecordRetrievalFail((count) => count + 1);
                    setValidationMsg(getFieldValue(fields, "ValidationError3"));
                  } else if (
                    (data.ResultCode = REGISTRATION_LOGIN_RESTRICTION)
                  ) {
                    setRecordRetrievalFail((count) => count + 1);
                    setValidationMsg(
                      getFieldValue(fields, "RegistrationLoginRestriction0")
                    );
                  }
                } else {
                  setValidationMsg(
                    getFieldValue(fields, "FormLevelErrorMessage")
                  );
                }
              })
              .catch(function (err) {
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          }
        }}
      >
        {({
          touched,
          errors,
          values,
          setFieldValue,
          handleBlur,
          setFieldTouched,
        }) => (
          <Form>
            <div className="Registration">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <section className="d-flex justify-content-center">
                    <div className="card lg-card">
                      <div className="card-body">
                        <StepProgressBar fields={fields} />
                        {/* -------------- */}
                        <div
                          className="directionsText"
                          style={{ marginBottom: "0.5rem", lineHeight: "20px" }}
                        >
                          {validateMembership && !memberIdError ? (
                            <div
                              className="validation-msg errorDisplay"
                              style={{
                                lineHeight: "20px",
                                height: "auto",
                              }}
                            >
                              {validateMembership}&nbsp;
                              {statusCode ===
                                VALIDATE_MEMBERSHIP_ATTEMPT_NEW && (
                                <a href="#" onClick={handleLogin}>
                                  {getFieldValue(fields, "lnkHelp").text}
                                </a>
                              )}
                            </div>
                          ) : (
                            // <Text field={fields.directions} />
                            <Text field={fields.dependingOnPlanType} />
                          )}
                        </div>
                        <div
                          className="row sub-gap"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          {/* {isSubmited && hasErrors(errors) ? ( */}
                          <div className="col-md-12 ">
                            <div className="text-center font-fill-indetails">
                              <b>
                                <Text field={fields.pleaseFillInTheFields} />
                              </b>
                            </div>
                          </div>

                          <div
                            className="col-md-12 sub-text text-center"
                            style={{ fontSize: ".7rem", color: "#ef2e2e" }}
                          >
                            <span className="text-center">
                              {isSubmited && hasErrors(errors) && (
                                <Text field={fields.allFieldsReq} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="member-info"
                          style={{ paddingTop: "0rem" }}
                        >
                          <div className="row-title">
                            <Text field={fields.enrollmentState} />
                            {required}
                            <div className="font-weight-500 custom-tooltip-spending pl-3 esInfo1">
                              <p className="custom-tooltip-spending-text tooltipBox">
                                <Text field={fields.stateText} />
                              </p>
                              <img
                                className="mb-2"
                                src={`${ICON_PATH}/info-24px.svg`}
                                alt={getFieldValue(fields, "infoIcon")}
                              />
                            </div>
                          </div>
                          <Field
                            name="enrollState"
                            as="select"
                            value={
                              enrollStateOptions
                                ? enrollStateOptions.find(
                                    (option) =>
                                      option.value === values.enrollState
                                  )
                                : ""
                            }
                            className={`form-gap ${highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}`}
                            error={highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}
                            aria-label={getFieldValue(
                              fields,
                              "enrollmentState"
                            )}
                            component={CustomSelect}
                            options={enrollStateOptions}
                            errors={highlightBorderOnError(
                              errors.enrollState,
                              touched.enrollState
                            )}
                            onChange={(option) => {
                              setFieldValue("enrollState", option.value);
                            }}
                            isFormik={true}
                          />
                          <div className="row-title">
                            <Text field={fields.dateOfBirth} />
                            {required}
                          </div>

                          <Field
                            component={Calendar}
                            name="dob"
                            handleChange={(selectedDate) => {
                              setSelectedDate(selectedDate);
                              selectedDate !== null
                                ? setFieldValue(
                                    "dob",
                                    selectedDate.toLocaleDateString()
                                  )
                                : setFieldValue("dob", null);
                            }}
                            value={selectedDate}
                            placeholder={getFieldValue(
                              fields,
                              "placeholderDateFormat",
                              false
                            )}
                            className={`${highlightBorderOnError(
                              errors.dob,
                              touched.dob
                            )}`}
                            aria-label={getFieldValue(fields, "dateOfBirth")}
                            invalidDateMessage={getFieldValue(
                              fields,
                              "lblInvalidDateError"
                            )}
                            minDateMessage={getFieldValue(
                              fields,
                              "lblMinDateError"
                            )}
                            maxDateMessage={getFieldValue(
                              fields,
                              "lblMaxDateError"
                            )}
                          />
                          <div className="row-title">
                            <Text field={fields.RadioButtonText} />
                            {required}
                          </div>
                          <div className="row mb-2">
                            <div className="col-1 row-title pr-0 remove-margin">
                              <Field
                                type="radio"
                                name="selectedOption"
                                value={MEMBER_ID_NO}
                                checked={selectedPlan === MEMBER_ID_NO}
                                //disabled={selectedPlan !== MEMBER_ID_NO}
                                className="pl-2"
                                onClick={(e) => handleChangeSSNorMemberID(e)}
                              />
                            </div>

                            <div className="col-11 pl-0">
                              {/* <p className="member-id-content form-content "> */}
                              <label
                                className="row-title remove-margin"
                                htmlFor="selectedOption"
                              >
                                <Text field={fields.MolinaMemberID} />
                              </label>
                              {/* </p> */}
                            </div>
                            <div className="col-1 row-title remove-margin pr-0">
                              <Field
                                type="radio"
                                name="selectedOption"
                                value={MEDICARE_CARD_NO}
                                checked={selectedPlan === MEDICARE_CARD_NO}
                                className="pl-2"
                                onClick={(e) => handleChangeSSNorMemberID(e)}
                              />
                            </div>

                            <div className="col-11 pl-0">
                              <label
                                className="row-title remove-margin"
                                htmlFor="selectedOption"
                              >
                                <Text field={fields.MedicareInsuranceNumber} />
                              </label>
                            </div>
                          </div>
                          <div className="infoStep1 row-title row-inline align-member-id-required">
                            {selectedPlan === MEMBER_ID_NO && (
                              <Text field={fields.MolinaMemberID} />
                            )}
                            {selectedPlan === SSN_NO && (
                              <Text field={fields.SocialSecurityNumber} />
                            )}
                            {selectedPlan === MEDICARE_CARD_NO && (
                              <Text field={fields.MedicareCardNumber} />
                            )}
                            {required}
                            {selectedPlan === SSN_NO && (
                              <div
                                className="row-sub-title ml-6"
                                style={{ display: "inline-flex" }}
                              >
                                <div className="font-weight-500 custom-tooltip-spending pl-3 ssnInfo1">
                                  <p className="custom-tooltip-spending-text tooltipBox">
                                    <Text field={fields.ssnNumberText} />{" "}
                                    <Link
                                      field={fields.lnkTermsOfUsePrivcyPolicy}
                                      target="_blank"
                                    />{" "}
                                    <Text field={fields.ssnNumberTextDetails} />
                                  </p>
                                  <img
                                    className="mb-2"
                                    src={`${ICON_PATH}/info-24px.svg`}
                                    alt={getFieldValue(fields, "infoIcon")}
                                  />
                                </div>
                              </div>
                            )}
                            {selectedPlan === MEMBER_ID_NO && (
                              <div className="row-sub-title ml-6">
                                <Text field={fields.howDoIFindMyMemberId} />
                                <div className="font-weight-500 custom-tooltip-spending pl-3">
                                  <p className="custom-tooltip-spending-text tooltipBox">
                                    <Text field={fields.memberIdText} />
                                  </p>
                                  <img
                                    className="mb-2"
                                    src={`${ICON_PATH}/info-24px.svg`}
                                    alt={getFieldValue(fields, "infoIcon")}
                                  />
                                </div>
                              </div>
                            )}
                            {selectedPlan === MEDICARE_CARD_NO && (
                              <div
                                className="row-sub-title ml-8 margin-tooltip-mo"
                                //style={{ display: "flex", marginLeft: "30px" }}
                              >
                                <Text field={fields.howDoIFindMyMedicareCard} />
                                <div className="font-weight-500 custom-tooltip-spending pl-3 MedicareInfoImg">
                                  <p
                                    className="custom-tooltip-spending-text tooltipBox"
                                    style={{ width: "350px" }}
                                  >
                                    <img
                                      className="mb-2"
                                      src={`${ICON_PATH}/Medicarecardnumber.png`}
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                      }}
                                    />
                                  </p>
                                  <img
                                    className="mb-2"
                                    src={`${ICON_PATH}/info-24px.svg`}
                                    alt={getFieldValue(fields, "infoIcon")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {selectedPlan === MEMBER_ID_NO && (
                            <Field
                              name="memberId"
                              type="text"
                              className={`member-width-id member-id-content form-control ${highlightBorderOnError(
                                errors.memberId,
                                touched.memberId
                              )}`}
                              onBlur={(e) =>
                                memberIdValidation(e, e.target.value)
                              }
                              onChange={(e) => {
                                let memId = e?.target?.value
                                  .replace(/\s+/g, "")
                                  .replace(/[^a-zA-Z0-9]/g, "");
                                setFieldValue("memberId", memId);
                              }}
                              onPaste={(e) => {
                                memberIdValidation(e, values, setFieldValue);
                              }}
                              aria-label={getFieldValue(fields, "memberId")}
                            />
                          )}
                          {memberIdError && (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {validateMembership}
                            </div>
                          )}
                          {selectedPlan === MEDICARE_CARD_NO && (
                            <>
                              <Field
                                name="medicareno"
                                type="text"
                                maxLength="4"
                                //pattern="[A-Za-z0-9]{4}"
                                className={`field-adjust-width-medicare member-id-content form-control ${highlightBorderOnError(
                                  errors.medicareno,
                                  touched.medicareno
                                )}`}
                                onChange={(e) => {
                                  let medicareIdNum = e?.target?.value.replace(
                                    /[^A-Za-z0-9]/g,
                                    ""
                                  );
                                  const nextField = document.querySelector(
                                    `input[name=medicareNoSecond]`
                                  );
                                  if (medicareIdNum.length >= 4) {
                                    nextField.focus();
                                  }
                                  setFieldValue("medicareno", medicareIdNum);
                                  setValidationMsg("");
                                }}
                                aria-label={getFieldValue(fields, "medicareno")}
                              />
                              <span>-</span>
                              <Field
                                name="medicareNoSecond"
                                type="text"
                                maxLength="3"
                                className={`field-adjust-width-medicare2 member-id-content form-control ${highlightBorderOnError(
                                  errors.medicareNoSecond,
                                  touched.medicareNoSecond
                                )}`}
                                onChange={(e) => {
                                  let medicareIdNum = e?.target?.value.replace(
                                    /[^A-Za-z0-9]/g,
                                    ""
                                  );
                                  setFieldValue(
                                    "medicareNoSecond",
                                    medicareIdNum
                                  );
                                  const nextField = document.querySelector(
                                    `input[name=medicareNoThird]`
                                  );
                                  if (medicareIdNum.length >= 3) {
                                    nextField.focus();
                                  }
                                  setValidationMsg("");
                                }}
                                aria-label={getFieldValue(
                                  fields,
                                  "medicareNoSecond"
                                )}
                              />
                              <span>-</span>
                              <Field
                                name="medicareNoThird"
                                type="text"
                                maxLength="4"
                                className={`field-adjust-width-medicare3 member-id-content form-control ${highlightBorderOnError(
                                  errors.medicareNoThird,
                                  touched.medicareNoThird
                                )}`}
                                onChange={(e) => {
                                  let medicareIdNum = e?.target?.value.replace(
                                    /[^A-Za-z0-9]/g,
                                    ""
                                  );
                                  setFieldValue(
                                    "medicareNoThird",
                                    medicareIdNum
                                  );
                                  setValidationMsg("");
                                }}
                                aria-label={getFieldValue(
                                  fields,
                                  "medicareNoThird"
                                )}
                              />
                            </>
                          )}
                          {errors.medicareno ||
                          errors.medicareNoSecond ||
                          errors.medicareNoThird ? (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {errors.medicareno ||
                                errors.medicareNoSecond ||
                                errors.medicareNoThird}
                            </div>
                          ) : null}

                          {medicareIdError && (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {validateMembership}
                            </div>
                          )}
                          {selectedPlan === SSN_NO && (
                            <>
                              <Field
                                name="SSN"
                                type={pwdInputType}
                                disabled
                                placeholder="XXX"
                                maxLength="3"
                                className={`field-adjust-width member-id-content form-control ${highlightBorderOnError(
                                  errors.memberId,
                                  touched.memberId
                                )}`}
                              />
                              <Field
                                name="ssnFeid2"
                                type={pwdInputType}
                                value={inputValueSecond}
                                placeholder="XX"
                                maxLength="2"
                                disabled
                                className={`field-adjust-width-ssn2 member-id-content form-control ${highlightBorderOnError(
                                  errors.memberId,
                                  touched.memberId
                                )}`}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  let SSN = e?.target?.value;
                                  if (SSN == "" || re.test(SSN)) {
                                    setInputValueSecond(SSN);
                                    setMaskedSSNSecond(SSN);
                                  }
                                  const nextField = document.querySelector(
                                    `input[name=ssnFeild3]`
                                  );
                                  if (SSN.length >= 2) {
                                    nextField.focus();
                                  }
                                }}
                                aria-label={getFieldValue(fields, "SSN")}
                              />
                              <span ref={refMsgDetailsOutside}>
                                <Field
                                  name="ssnFeild3"
                                  type={pwdInputType}
                                  placeholder="XXXX"
                                  ref={(ref) => (refMsgDetails.current = ref)}
                                  maxLength="4"
                                  className={`field-adjust-width-ssn3 member-id-content form-control ${highlightBorderOnError(
                                    errors.ssnFeild3,
                                    touched.ssnFeild3
                                  )}`}
                                  onBlur={(e) => {
                                    // Call Formik's handleBlur to automatically handle touch status and validation
                                    handleBlur(e);
                                    // Optionally, ensure the field is touched to show the error message
                                    setFieldTouched("ssnFeild3", true, true);
                                    setVisible(false);
                                  }}
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    let SSN = e?.target?.value;
                                    if (SSN == "" || re.test(SSN)) {
                                      setFieldValue("ssnFeild3", SSN);
                                    }
                                  }}
                                  aria-label={getFieldValue(
                                    fields,
                                    "ssnFeild3"
                                  )}
                                />
                                <i>{pwdVisibilityIcon}</i>
                              </span>
                            </>
                          )}
                          {errors.ssnFeild3 && touched.ssnFeild3 && (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {errors.ssnFeild3}
                            </div>
                          )}
                          {/* Confirm ssn */}
                          <div className="row-title row-inline align-member-id-required">
                            {selectedPlan === SSN_NO && (
                              <>
                                <Text
                                  field={fields.ConfirmSocialSecurityNumber}
                                />
                                {required}
                              </>
                            )}
                          </div>
                          {selectedPlan === SSN_NO && (
                            <>
                              <Field
                                name="confirmSSN"
                                type={pwdInputType}
                                disabled
                                placeholder="XXX"
                                maxLength="3"
                                className={`field-adjust-width member-id-content form-control ${highlightBorderOnError(
                                  errors.memberId,
                                  touched.memberId
                                )}`}
                              />
                              <Field
                                name="confirmSsnFeid2"
                                type={pwdInputType}
                                value={inputValueSecond}
                                placeholder="XX"
                                maxLength="2"
                                disabled
                                className={`field-adjust-width-ssn2 member-id-content form-control ${highlightBorderOnError(
                                  errors.memberId,
                                  touched.memberId
                                )}`}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  let SSN = e?.target?.value;
                                  if (SSN == "" || re.test(SSN)) {
                                    setInputValueSecond(SSN);
                                    setMaskedSSNSecond(SSN);
                                  }
                                  const nextField = document.querySelector(
                                    `input[name=confirmSsnField3]`
                                  );
                                  if (SSN.length >= 2) {
                                    nextField.focus();
                                  }
                                }}
                                aria-label={getFieldValue(fields, "SSN")}
                              />
                              <span ref={refConfirmSSNOutside}>
                                <Field
                                  name="confirmSsnField3"
                                  type={pwdInputTypeConfirm}
                                  placeholder="XXXX"
                                  ref={(ref) => (refMsgDetails.current = ref)}
                                  maxLength="4"
                                  className={`field-adjust-width-ssn3 member-id-content form-control ${highlightBorderOnError(
                                    errors.confirmSsnField3,
                                    touched.confirmSsnField3
                                  )}`}
                                  onBlur={(e) => {
                                    // Call Formik's handleBlur to automatically handle touch status and validation
                                    handleBlur(e);
                                    // Optionally, ensure the field is touched to show the error message
                                    setFieldTouched(
                                      "confirmSsnField3",
                                      true,
                                      true
                                    );
                                    setConfirmVisible(false);
                                  }}
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    let SSN = e?.target?.value;
                                    if (SSN == "" || re.test(SSN)) {
                                      setFieldValue("confirmSsnField3", SSN);
                                    }
                                  }}
                                  aria-label={getFieldValue(
                                    fields,
                                    "confirmSsnField3"
                                  )}
                                />
                                <i>{pwdVisibilityIconConfirm}</i>
                              </span>
                            </>
                          )}
                          {/* COnfirm SSN end */}
                          {errors.confirmSsnField3 ? (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {errors.confirmSsnField3}
                            </div>
                          ) : null}
                          {ssnError && (
                            <div
                              className="validation-msg"
                              style={{ height: "auto" }}
                            >
                              {validateMembership}
                            </div>
                          )}

                          <div className="row mt-3">
                            <div className="col-md-12">
                              <div className="registration-btn1">
                                <button
                                  type="button"
                                  className="btn btn-primary ripple-surface1 btn-1"
                                  onClick={() => {
                                    location = "/";
                                  }}
                                >
                                  <Text field={fields.cancelButton} />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={memberIdError}
                                  onClick={() => setIsSubmited(true)}
                                >
                                  <Text field={fields.nextButton} />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row step1-reg">
                            <div>
                              <Text field={fields.alreadyRegistered} />
                              <a
                                href="#"
                                className="sign-link text-zoom"
                                onClick={handleLogin}
                              >
                                {getFieldValue(fields, "lnkSignIn").text}
                                <img
                                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                  alt={getFieldValue(fields, "lnkSignIn").text}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MemberInformation;
